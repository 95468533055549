import * as React from "react"
import { useEffect } from "react";

const NikeRedirect = () => {
    useEffect(
        () => {
            const windowUrl = window.location.search;
            const params = new URLSearchParams(windowUrl);

            window.location.assign('mynike://x-callback-url/product-details?style-color=' + params.get('id'));
        }
    )

    return(
        <></>
    )
}

export default NikeRedirect;